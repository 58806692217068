import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
  <nav id="menu">
    <div className="inner">
      <ul className="links">
        <li>
          <Link onClick={props.onToggleMenu} to="/">
            Accueil
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/#services">
            Services
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/philosophy">
            Philosophie
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/gallery">
            Galerie
          </Link>
        </li>
        <li>
          <Link onClick={props.onToggleMenu} to="/#contact">
            Contact
          </Link>
        </li>
      </ul>
    </div>
    <button className="close" onClick={props.onToggleMenu}>
      Close
    </button>
  </nav>
)

Menu.propTypes = {
  onToggleMenu: PropTypes.func,
}

export default Menu
