import React, { useCallback } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

const Contact = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const onSubmit = useCallback(
    async (event) => {
      try {
        event.preventDefault()
        if (!executeRecaptcha) throw new Error('Recaptcha is missing')
        const form = event.target
        const data = new FormData(form)
        const result = await executeRecaptcha()
        data.append('recaptcha_response', result)
        const success = await fetch('./mailer.php', {
          method: 'POST',
          body: data,
        })
        const message = await success.text()
        console.log(success)
        console.log(message)
        if (success.ok) alert(message)
        else throw new Error(message)
        form.reset()
      } catch (e) {
        console.error(e)
        alert(`erreur: ${e}`)
      }
    },
    [executeRecaptcha]
  )

  return (
    <section id="contact">
      <div className="inner">
        <section>
          <form method="post" action="#" onSubmit={onSubmit}>
            <div className="field">
              <label htmlFor="name">Nom</label>
              <input
                type="text"
                aria-labelledby="name"
                id="name"
                name="name"
                required
              />
            </div>
            <div className="field half first">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                aria-labelledby="email"
                id="email"
                name="email"
                required
              />
            </div>
            <div className="field half">
              <label htmlFor="phone">Téléphone</label>
              <input
                type="tel"
                aria-labelledby="phone"
                id="phone"
                name="phone"
              />
            </div>
            <div className="field">
              <label htmlFor="name">Adresse</label>
              <input
                type="text"
                aria-labelledby="address"
                id="address"
                name="address"
              />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea
                aria-labelledby="message"
                rows="6"
                id="message"
                name="message"
                required
              ></textarea>
            </div>
            <div
              className="actions"
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                width: '100%',
              }}
            >
              <ul className="actions">
                <li>
                  <input type="reset" aria-label="Effacer" value="Effacer" />
                </li>
                <li>
                  <input
                    type="submit"
                    aria-label="Envoyer"
                    value="Envoyer"
                    className="special"
                  />
                </li>
              </ul>
            </div>
          </form>
        </section>

        <section className="split">
          <section>
            <div className="contact-method">
              <span className="icon alt fa-facebook"></span>
              <h3>Facebook</h3>
              <a href="https://www.facebook.com/nicolas.elagueur/">
                /nicolas.elagueur
              </a>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-phone"></span>
              <h3>Téléphone</h3>
              <a href="tel:+32472845887">+32 472 84 58 87</a>
            </div>
          </section>
          <section>
            <div className="contact-method">
              <span className="icon alt fa-home"></span>
              <h3>Adresse</h3>
              <span>
                95 avenue des Ortolans,
                <br />
                1170 Bruxelles,
                <br />
                Belgique
              </span>
            </div>
          </section>
        </section>
      </div>
    </section>
  )
}

export default Contact
